<template>
  <div class="container">
    <Header />
    <div class="banner">
        <ul class="leftNode"> 
          <li>
             <img src="../../assets/image/方案1.png" alt="">
          </li>
        </ul>
        <ul class="rightNode">
          <li>
                 <img src="../../assets/image/icon1.png" alt="">
                 <h2>招采门户</h2>
                 <p>为您提供物资、设备、工程、服务、机械设备等各行各业的招标采购、询价采购的采购公告和中标公示。</p>
                 <input type="button"  value="立即体验"  class="btn">
          </li>
          <li>
                 <img src="../../assets/image/icon2.png" alt="">
                 <h2>数字货运</h2>
                 <p>通过向司机派单或司机扫码接单的方式，进行线上运输业务。通过制单、电子合同、线上履约、运输可视化、银企直联、线上支付、在线开票...</p>
                 <input type="button" value="立即体验" class="btn">
          </li>
          <li>
                 <img src="../../assets/image/icon3.png" alt="">
                 <h2>铜价资讯</h2>
                 <p>为您提供物资、设备、工程、服务、机械设备等各行各业的招标采购、询价采购的采购公告。</p>
                 <input type="button" value="立即体验" class="btn">
          </li>
        </ul>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from "../Header/index.vue";
import Footer from  "../Footer/index.vue"
export default {
  name: "AppSolution",
  components: {
    Header,
    Footer
  },
};
</script>
<style lang="less" scoped>
li{
  list-style: none;
}
.banner {
  padding: 0 60px ;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #DEE7F8;
}

.leftNode {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.rightNode {

  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  li {
  flex-basis: 33.33%;
  width: 361px;
  height: 361px;
  text-align: center;
  margin: 0 20px;
  background-color: #fff;
  P{
   margin: 10px;
  }
     .btn{
 
      width: 300px;
      height: 40px;
      margin-top: 30px;
      background-color: #00B2FF;
      border:none
     }
}
}
</style>