// router/index.js
import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../pages/Main.vue'
import AppSolution from '../pages/AppSolution/index.vue'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Main',
      component: Main,



    }, {
      path: '/AppSolution',
      name: 'AppSolution',
      component: AppSolution
    }
  ]
})

const originnalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  // console.log(location);   // 路由的name和path
  return originnalPush.call(this, location).catch(err => err)
}
router.beforeEach((to, from, next) => {
  // 在这里进行必要的操作，例如权限校验、页面滚动等
  next()
})
export default router