<template>
  <div class="container">
    <div class="expo">
      <h1 style="display: block;">博缆五车</h1>
      <p style="display: block;">博洛克为供应链整个链条上的所有企业、环节提供更全面的服务。</p>
      <ul>
        <li>
          <img src="@/assets/image/B6.png" alt="">
        </li>
        <li>
          <img src="@/assets/image/B1.png" alt="">
        </li>
        <li>
          <img src="@/assets/image/B2.png" alt="">
        </li>
        <li>
          <img src="@/assets/image/B3.png" alt="">
        </li>
      </ul>
    </div>
    <div class="procurement">
      <h1 style="display: block;">招采门户</h1>
      <p style="display: block;">为您提供物资、设备、工程、服务、机械设备等各行各业的招标采购、询价采购的采购公告和中标公示。</p>
      <ul>
        <li>
          <img src="@/assets/image/采购公告.png" alt="">
        </li>
        <li>
          <img src="@/assets/image/询价采购.png" alt="">
        </li>
        <li>
          <img src="@/assets/image/询价采购-详情.png" alt="">
        </li>
        <li>
          <img src="@/assets/image/我要响应.png" alt="">
        </li>
      </ul>
    </div>
    <div class="digitalFreight">
      <h1 style="display: block;">数字货运</h1>
      <p style="display: block;">
        通过向司机派单或司机扫码接单的方式，进行线上运输业务。通过制单、电子合同、线上履约、运输可视化、银企直联、线上支付、在线开票等环节，将传统线下物流运营模式转向线上模式。相辅相成（线下传统物流企业借助数字化技术方案，进行升级、转型，以寻找到更多、更优质的货源；线上服务企业通过数字化技术方案，来给传统物流企业赋能未来，以来寻求立足之地）
      </p>
      <ul>
          <li>
            <img src="@/assets/image/数字1.png" alt="">
          </li>
          <li>
            <img src="@/assets/image/数字2.png" alt="">
          </li>
          <li>
            <img src="@/assets/image/数字3.png" alt="">
          </li>
          <li>
            <img src="@/assets/image/数字4.png" alt="">
          </li>
          <li>
            <img src="@/assets/image/数字5.png" alt="">
          </li>
          <li>
            <img src="@/assets/image/数字6.png" alt="">
          </li>
        </ul>
    </div>
    <div class="copperPrice">
      <h1 style="display: block;">铜价资讯</h1>
      <p style="display: block;">为您提供物资、设备、工程、服务、机械设备等各行各业的招标采购、询价采购的采购公告和中标公示。</p>
      <ul>
        <li>
          <img src="@/assets/image/B5.png" alt="">
        </li>
        <li>
          <img src="@/assets/image/B2.png" alt="">
        </li>
        <li>
          <img src="@/assets/image/B3.png" alt="">
        </li>
        <li>
          <img src="@/assets/image/B4.png" alt="">
        </li>
      </ul>

    </div>
    <div class="partners">
      <h1 style="display: block;">合作伙伴</h1>
      <ul>
        <li>
          <img src="@/assets/image/合作伙伴1.png" alt="">
        </li>
        <li>
          <img src="@/assets/image/合作伙伴2.png" alt="">
        </li>
        <li>
          <img src="@/assets/image/合作伙伴3.png" alt="">
        </li>
        <li>
          <img src="@/assets/image/合作伙伴4.png" alt="">
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default({
  name: "Home",
})
</script>
<style lang="less" scoped>
body {
  overflow-x: hidden;
}
.container {
  width: 100%;
 
 
  .expo {
    width: 100%;
  
    margin: 0 auto;
    background-color: #E0ECFF;
    text-align: center;
    line-height: 1.5;

    h1,
    p {
      margin: 0;
    }

    p {
      margin-bottom: 20px;
    }

   ul {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      margin-top: 20px;
    }

    li {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
  }
  .procurement {
    width: 100%;
  
    margin: 0 auto;
    background-color: #E0ECFF;
    text-align: center;
    line-height: 1.5;

    h1,
    p {
      margin: 0;
    }

    p {
      margin-bottom: 20px;
    }

   ul {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      margin-top: 20px;
    }

    li {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
  }

  .digitalFreight {
    width: 100%;

    margin: 0 auto;
    background-color: #E0ECFF;
    text-align: center;
    line-height: 1.5;

    p {
      text-align: center;
      margin: 0 25px;
      font-size: 18px;
      
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        float: left;
        width: 50%;
        box-sizing: border-box;
        padding: 10px;

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      /* add the correct file paths for the images */
      li:first-child img {
        content: url("@/assets/image/数字1.png");
      }
      li:last-child img {
        content: url("@/assets/image/数字2.png");
      }
    }
  }

  .copperPrice {
    width: 100%;
  
    margin: 0 auto;
    background-color: #E0ECFF;
    text-align: center;
    line-height: 1.5;

    h1,
    p {
      margin: 0;
    }

    p {
      margin-bottom: 20px;
    }

   ul {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      margin-top: 20px;
    }

    li {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
  }

  .partners {
    width: 100%;
    // height: 500px;
    margin: 0 auto;
    background-color: #E0ECFF;
    text-align: center;
    line-height: 1.5;
    margin-top: -10px;

    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin-right: 20px;

        &:last-of-type {
          margin-right: 0;
        }

        img {
          display: block;
          max-width: 100%;
          height: auto;
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>