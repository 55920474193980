export default ({
    namespaced: true,
    actions: {},
    mutations: {
        Flagt(state){
            state.isShowli = true
        },
        Flagf(state){
            state.isShowli = false
        }
    },
    state: {
        nav: [
            {
                title: '游戏资料',
                subtitle: 'GAME INFO'
            },
            {
                title: '商城/合作',
                subtitle: 'STORE'
            },
            {
                title: '社区互动',
                subtitle: 'COMMUNITY'
            },
            {
                title: '赛事官网',
                subtitle: 'ESPORTS'
            },
            {
                title: '自助系统',
                subtitle: 'SYSTEM'
            },
        ],
        Games: [
            {
                name: 'Game',
                content: ['游戏下载', '新手指引', '资料库', '云顶之弈', '攻略中心', '开发者基地', '海克斯战利品', '英雄联盟宇宙'],
            },
            {
                name: 'Store',
                content: ['点券充值', '道聚城', '周边商城', 'LOL桌游', '网吧特权', '电竞小说'],
            },
            {
                name: 'Community',
                content: ['视频中心', '官方微信', '官方微博', '玩家创作馆', '玩家服务', 'LOL组队专区', '作者入驻计划', '英雄联盟素材库'],
            },
            {
                name: 'Esports',
                content: ['全球总决赛', '季中赛冠军', 'LPL职业联赛', 'LDL发展联赛', '城市英雄争霸赛', '德玛西亚杯', '全国高校联赛', '云顶之奕公开赛'],
            },
            {
                name: 'System',
                content: ['图标自动领取', '转区系统', '封号查询', '账号注销', '信誉分系统', '服务器状态查询', '秩序殿堂', '峡谷之巅'],
            }
        ],
        isShowli: false

    },
    getters: {}
})