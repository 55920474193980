export default {
    namespaced: true,
    actions: {},
    mutations: {},
    state: {
        
        link: {
            map: [
                '腾讯互动娱乐',
                '服务条款',
                '隐私保护指引',
                '儿童隐私保护指引',
                '腾讯游戏招聘',
                '腾讯游戏客服',
                '游戏列表',
                '广告服务及商务合作'
            ],
            zh: [
                '腾讯公司版权所有',
                '网络游戏行业防沉迷自律公约'
            ],
            pis: [
                'COPYRIGHT © 1998 - 2022 TENCENT. ALL RIGHTS RESERVED.',
                'COPYRIGHT © 2012 Riot Games,Inc. ALL RIGHTS RESERVED.',
                '本网络游戏适合16+岁的用户使用；为了您的健康，请合理控制游戏时间。'
            ],
            public: {
                _src: '	https://game.gtimg.cn/images/js/2018foot/logo/gswj.png',
                title: [
                    '工商网监电子标识 ',
                    '粤网文[2020]3396-195号',
                    '（署）网出证（粤）字第054号'
                ]
            },
            private: '批准文号：新出审字[2011]310号 |文网进字[2011] 004号 | 出版物号：ISBN 978-7-89989-145-2|全国文化市场统一举报电话：12318'

        }
    },
    getters: {}
}