<!-- header/index.vue -->
<template>
  <div class="header">
    <ul class="leftNode">
      <img src="@/assets/image/block2025-logo-png.png" class="logo" alt="">

      <li>
        <img src="@/assets/image/应用方案.png" class="minImg" alt="">
        <router-link to="/" style="margin-right: 10px;">首页</router-link>
        <img src="@/assets/image/应用方案.png" class="minImg" alt="">
        <router-link to="/AppSolution" style="margin-right: 10px;">应用方案</router-link>
      </li>

      <li>
        <img src="@/assets/image/工作资源.png" class="minImg" alt="">
        <a href="#">工作资源</a>
      </li>

      <li>
        <img src="@/assets/image/谷歌浏览器.png" class="minImg" alt="">
        <a href="#">谷歌浏览器下载</a>
      </li>
    </ul>
    <ul class="rightNode">
      <li class="obj">
        <img src="@/assets/image/工作台.png" class="minImg" alt="">
        <a href="#">工作台</a>
      </li>
      <li class="btn" v-if="loggedIn">
        {{ user.name }}
        <a href="#" @click="logout">退出</a>
      </li>
      <li class="btn" v-else>
        <a href="#" @click="showLoginDialog">
          <el-button type="primary" plain style="background-color: #91D5FF; width: 100%; border: none;"
            class="no-border">登录</el-button>
        </a>
      </li>
    </ul>

    <el-dialog center width="20%" :visible.sync="loginDialogVisible" title="登录">
      <el-tabs v-model="activeTab">
        <el-tab-pane label="短信登录" name="phone">
          <el-form :model="loginForm" ref="Form" :rules="rules" label-width="110px">
            <el-form-item label="手机号码" prop="phone">
              <el-input v-model="loginForm.phone" prefix-icon="el-icon-mobile" placeholder="请输入手机号码"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="code">
              <el-input v-model="loginForm.code" prefix-icon="el-icon-chat-dot-round" placeholder="请输入验证码"></el-input>
              <div v-if="smsCode">{{ smsCode }}</div>
              <el-button type="text" @click="sendSMSCode" :disabled="countdown > 0">{{ countdown > 0 ? countdown + 's' :
                '获取验证码' }}</el-button>

            </el-form-item>
            <el-form-item>
              未注册的手机号码将自动创建账号
            </el-form-item>
            <el-form-item>
              <div id="signedAgreement">
                <input type="checkbox" id="cr" style="-webkit-box-shadow: 0 0 0 #fff;-moz-box-shadow: 0 0 0 #fff;"
                  v-model="agreement">
                <span style="cursor: pointer">我已阅读并同意<span class="link">《用户协议》</span></span>
              </div>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="密码登录" name="sms">
          <el-form :model="smsLoginForm" ref="passForm" :rules="rules" label-width="110px">
            <el-form-item label="手机号码" prop="phone">
              <el-input v-model="smsLoginForm.phone" prefix-icon="el-icon-mobile" placeholder="请输入手机号码"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input v-model="smsLoginForm.password" prefix-icon="el-icon-lock" type="password"
                placeholder="请输入密码"></el-input>

            </el-form-item>

            <el-form-item>
              未注册的手机号码将自动创建账号 <a href="#" @click="showForgotPasswordDialog = true" style="color: #91D5FF;float: right;">
                <i class="el-icon-question"></i>忘记密码</a>
            </el-form-item>
            <el-form-item>
              <div id="signedAgreement">
                <input type="checkbox" id="cr" style="-webkit-box-shadow: 0 0 0 #fff;-moz-box-shadow: 0 0 0 #fff;"
                  v-model="agreement">
                <span style="cursor: pointer">我已阅读并同意《用户协议》</span>
              </div>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">

        <el-button type="primary" @click="login">{{ activeTab === 'phone' ? '手机号码登录' : '短信登录' }}</el-button>
        <el-button @click="loginDialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog center :visible.sync="showForgotPasswordDialog" title="忘记密码" width="15%" :close-on-click-modal="false">
      <el-form :model="forgotPasswordForm" ref="forgotPasswordForm" :rules="forgotPasswordRules" label-width="80px">
        <el-form-item label="新密码" prop="newPassword">
          <el-input v-model="forgotPasswordForm.newPassword" type="password" placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input v-model="forgotPasswordForm.confirmPassword" type="password" placeholder="请确认新密码"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="submitForgotPasswordForm">确定</el-button>
        <el-button @click="showForgotPasswordDialog = false">取消</el-button>

      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "Header",

  data() {
    var validatePhone = (rule, value, callback) => {
      // 判断手机号是否为空
      if (!value) {
        callback(new Error('手机号不能为空'));
        return;
      }

      // 手机号正则校验
      var uPattern = /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/;
      if (uPattern.test(value)) {
        callback();
      } else {
        callback(new Error('手机号格式不正确'));
      }
    };
    var validatePassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error('密码不能为空'));
      }
      // else if (! /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,16}$/.test(value)) {
      //   callback(new Error('密码必须包含字母和数字，长度为8-16位'));
      // } 
      else {
        callback();
      }
    }
    var validateCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error('验证码不能为空'));
        return;
      }
      else {
        callback();
      }
    }
    return {
      agreement: false, // 是否同意协议
      loggedIn: false,
      user: {},
      loginDialogVisible: false,
      loginForm: {
        phone: '',
        code: ''
      },
      smsCode: '',
      smsLoginForm: {
        phone: '',
        password: ''
      },
      activeTab: 'phone',
      countdown: 0,
      smsCountdown: 0,
      rules: {
        phone: [{ required: true, message: '请输入手机号码', trigger: 'blur' },
        { validator: validatePhone, trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' },
        { validator: validatePassword, trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' },
        { validator: validateCode, trigger: 'blur' }],
      },
      showForgotPasswordDialog: false,
      forgotPasswordForm: {
        newPassword: '',
        confirmPassword: ''
      },
      forgotPasswordRules: {
        newPassword: [
          { required: true, message: '新密码不能为空', message: '请输入密码', trigger: 'blur' },
          // { pattern: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,16}$/, message: '新密码必须包含字母和数字，长度为8-16位', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: '确认密码不能为空', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value !== this.forgotPasswordForm.newPassword) {
                callback(new Error('两次输入的密码不一致'));
              } else {
                callback();
              }
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    showLoginDialog() {
      this.loginDialogVisible = true;
    },
    login() {
      if (this.activeTab === 'phone') {
        this.$refs.Form.validate(valid => {
          if (valid && this.agreement) { // 验证通过且同意协议
            // 进行登录操作
            this.loggedIn = true;
            this.user = {
              name: this.loginForm.phone,
            };
            this.loginDialogVisible = false;
          }
          else if (!this.agreement) {
            this.$message.warning('请先勾选同意协议');
          }
        });
      } else {
        this.$refs.passForm.validate(valid => {
          if (valid && this.agreement) { // 验证通过且同意协议
            // 进行登录操作
            this.loggedIn = true;
            this.user = {
              name: this.smsLoginForm.phone,
            };
            this.loginDialogVisible = false;
          }
          else if (!this.agreement) {
            this.$message.warning('请先勾选同意协议');
          }
        });
      }

    },
    sendSMSCode() {
      if (this.countdown > 0) {
        return;
      }
      if (!this.loginForm.phone) {
        this.$message.warning('请先输入手机号码');
        return;
      }
      // 生成随机数作为验证码
      this.loginForm.code = Math.floor(Math.random() * 1000000).toString();
      this.countdown = 60; // 开始倒计时
      let timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(timer);
        }
      }, 1000);
    },

    submitForgotPasswordForm() {
      this.$refs.forgotPasswordForm.validate((valid) => {
        if (valid) {
          // 发送修改密码请求
          setTimeout(() => {
            this.showForgotPasswordDialog = false;
            this.$message.success('修改密码成功');
          }, 500); // 设置延迟时间为 2 秒钟
        } else {
          this.$message.error('请正确填写表单');
        }
      });
    },
    logout() {
      this.loggedIn = false;
      this.user = null;
    }
  },

};
</script>

<style lang="less" scoped>
li {
  list-style: none;
}

/deep/ .el-tabs__nav {
  width: 100%;

  .el-tabs__item {
    width: 50%;
    text-align: center;
  }
}

.header {
  padding: 0;
  width: 100%;
  background-color: #E0ECFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  // margin-bottom: -20px;


  .leftNode {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;

    .logo {
      padding-top: 10px;
      width: 150px;
      height: auto;
      margin-right: 20px;
    }

    li {
      padding: 0 20px;
      display: inline-flex;
      align-items: center;
      text-align: center;
      font-size: 18px;
      color: #666;
      justify-content: center;

      .minImg {
        width: 25px;
        line-height: 65px;
        margin-top: 10px;
        margin-right: 5px;
        vertical-align: middle;
        flex-shrink: 0;
      }

      a {
        padding-top: 10px;
        color: #666;
      }
    }
  }

  .rightNode {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btn {
      height: 100%;
      line-height: 40px;
      font-size: 18px;
      padding: 0 20px;
      border-radius: 20px;
    }

    .obj {
      height: 100%;
      line-height: 40px;
      font-size: 18px;

      padding: 0 20px;

      .minImg {
        width: 25px;
        line-height: 65px;

        margin-top: 3px;
        margin-right: 5px;
        vertical-align: middle;
        flex-shrink: 0;
        align-items: center;
      }
      a {
        padding-top: 10px;
        color: #666;
      }
      // a {
      //   color: #fff;
      // }

      a:hover {
        color: #4894F4;
      }
    }
  }
}

.link {
  color: #00B2FF;
  /* 设置链接文本颜色为蓝色 */
}
.no-border {
  border-radius: 20px;
}
</style>