<template>
  <div class="footer">
        <ul class="image-row">
          <li><img src="@/assets/image/图1.png" alt=""></li>
          <li><img src="@/assets/image/图2.png" alt=""></li>
          <li><img src="@/assets/image/图3.png" alt=""></li>
          
          <li><img src="@/assets/image/图4.png" alt=""></li>
        </ul>
        <div class="intro">
          <div class="left">
            <img src="@/assets/image/block2025-logo-png.png" alt="">
            <p style="margin-top: 10px; color: #9f9378; ">
              安徽博洛克供应链科技股份有限公司（以下简称博洛克供应链）成立于2020年，公司注册资本2亿（一期3000万）是一家“互联网+运贸融”一体化平台型公司，公司位于安徽省无为市高新工业园区。博...</p>
          </div>
          <div class="right">
            <ul>
              <li><img src="@/assets/image/微信二维码.png" alt=""></li>
              
              
              <li><img src="@/assets/image/公众号.png" alt=""></li>
            </ul>
          </div>
        </div>

        <p style="color: #9f9378; ">© 2020-2023 block2025.com安徽博洛克供应链科技股份有限公司
          <el-link  style="color: #9f9378; font-size: 1rem; margin-top: -3px;" :underline="false" href="https://beian.miit.gov.cn/"
                >互联网ICP备案：皖ICP备20009156号-1</el-link
              >
        </p>
      </div>
</template>
<script>
export default({
  name: "Footer",
})
</script>
<style lang="less" scoped>
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .image-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    list-style: none;

    li {
      margin-right: 20px;

      &:last-of-type {
        margin-right: 0;
      }

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
  }

  .intro {
    // height: 210px;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;

    .left {
      height: 100%;
      width: 30%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      img {
        display: block;
        max-width: 100%;
        height: auto;
        margin-right: 20px;
      }

      p {
        height: 100%;
        margin: 0;
        /* height: 3em;  */
        line-height: 1.5em; 
        overflow: hidden; 
        text-overflow: ellipsis; 
      }
    }

    .right {
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      ul {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          margin-left: 20px;

          &:first-of-type {
            margin-left: 0;
          }

          img {
            display: block;
            max-width: 100%;
            height: auto;
          }
        }

        &:first-of-type li:first-of-type {
          margin-left: auto;
          margin-right: 20px;
        }
      }
    }
  }
}
</style>