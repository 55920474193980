<template>
    <div class="banner">
      <img src="@/assets/image/image.png" alt="">
    </div>
  </template>
  
  <script>
  export default {
    name: "Banner",
  };
  </script>
  
  <style lang="less" scoped>
  .banner {
    height: 100vh;
    width: 100%;

    // height: 65px;
    background-color: #E0ECFF;
    img {
      height: 100%;
      width: 100%;
    }
  }
  </style>