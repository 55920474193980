<template>
  <div class="container">
    <div class="box">
      <el-container>
        <el-header>
          <Header />
        </el-header>
        <el-main>
          <Banner />
          <Home />
        </el-main>
        <el-footer>
          <Footer />
        </el-footer>
      </el-container>
    </div>
  </div>
</template>

<script>
import Header from "./Header/index.vue";
import Home from "./Home/index.vue";
import Footer from "./Footer/index.vue";
import Banner from "./Banner/index.vue"
export default {
  name: "Main",
  components: {
    Header,
    Home,
    Footer,
    Banner
  },
};
</script>

<style lang="less" scoped>
html,
body {
  height: 100%; /* 设置 html 和 body 的高度为 100% */
  margin: 0;
  padding: 0;
}

// .container {
//   // height: 100vh; /* 设置父元素的高度为视口高度 */
//   // overflow: hidden; /* 隐藏溢出内容 */
// }

.box {
  width: 100%; /* 使用百分比来设置宽度 */
  // max-width: 1920px; /* 最大宽度为 1920px */
  height: 100%; /* 使用百分比来设置高度 */
  margin: 0 auto;
  .el-container {
    width: 100%; /* 使用百分比来设置宽度 */
    // background-color: rgb(238, 238, 238);
  }
  .el-header {
    height: 10%; /* 使用百分比来设置高度 */
    padding: 0;
  }
  .el-main {
    margin-top: -30px;
    width: 100%;
    height: auto;
   padding: 20px 0;
  }
  .el-footer {
    height: 5%; /* 使用百分比来设置高度 */
    padding: 3% 0; /* 使用百分比来设置内边距 */
    // background-color: #e3e2e2;
    color: #9f9378;
    font-size: 1rem; /* 使用 rem 来设置字体大小 */
    line-height: 2rem; /* 使用 rem 来设置行高 */
  }
  @media screen and (max-width: 768px) {
    /* 在屏幕宽度小于 768px 时应用以下样式 */
    .el-header {
      height: 120px;
    }
    .el-footer {
      height: 80px;
    }
  }
}
</style>
